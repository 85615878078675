import css from "./style.module.css";
const Servicec = () => {
  return (
    <div className={css.wrapServiced} id="section2">
      <div className={css.wrapSmallService}>
        <p className={css.servPMain}>Ми раді запропонувати наступні послуги:</p>
        <div className={css.wrapServicec}>
          <div className={css.wrapInfDSsa}>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Вакцинація</p>
            </div>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Діагностика</p>
            </div>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Лабораторні дослідження</p>
            </div>{" "}
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Кастрація</p>
            </div>
          </div>
          <div className={css.wrapInfDSsa}>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Дерматологія</p>
            </div>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Стерилізація</p>
            </div>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Хірургічні операції</p>
            </div>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Профілактичні огляди</p>
            </div>
          </div>
          <div className={css.wrapInfDSsa}>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Стоматологія</p>
            </div>

            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Консультації з харчування</p>
            </div>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Загальна терапія</p>
            </div>
            <div className={css.wrapServCirk}>
              <div className={css.circuitYu}></div>
              <p className={css.servNamr}>Інтенсивна терапія</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Servicec;
