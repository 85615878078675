import css from "./style.module.css";
import imageAbout from "../../img/cms-banner-2.jpg";
import twoImage from "../../img/09-470x713.jpg";
import losa from "../../img/14-527x800-removebg-preview.png";
import cat from "../../img/10-527x800-removebg-preview.png";
import browForm from "../../img/dsd.jpg";
import somTwo from "../../img/Fotoobrazy_Specific.jpg";
import somThre from "../../img/cymedica_logo.jpg";
import somFours from "../../img/logoped.png";
import somFive from "../../img/arteriuLogo.png";
import somSix from "../../img/qweSd.png";
const Zoo = () => {
  return (
    <div className={css.wrapAboutZoo} id="section3">
      <div className={css.wrapDescInm}>
        <p className={css.aboutJkD}>Ветеринарна аптека</p>
        <p className={css.pDecAboutD}>
          У нашій ветеринарній клініці LarinVet також є ветеринарна аптека де
          представлиений ряд препаратів широкого спектру дії. Пропонуємо вам
          сучасні антибактеріальні, підтримуючі та профілактичні засоби, а також
          лікувальні дієтичні корми для вошого улюбленця. Засоби гігієни та
          догляду. Наші фахівці завжди готові допомогти вам з вибором та надати
          професійні індивідуальні консультації щодо правильного використання
          лікрських засобів. Ми піклуємося про здоров'я та комфорт ваших тварин,
          тому обираємо тільки найкращі товари від надійних виробників.
        </p>
      </div>
      <div className={css.wrapDescInmSecondZoo}>
        <img src={browForm} className={css.wrapImaged} alt="Photo" />
        <img src={somTwo} className={css.wrapImaged} alt="Photo" />
        <img src={somThre} className={css.wrapImaged} alt="Photo" />
        <img src={somFours} className={css.wrapImaged} alt="Photo" />
        <img src={somFive} className={css.wrapImaged} alt="Photo" />
        <img src={somSix} className={css.wrapImaged} alt="Photo" />
      </div>
    </div>
  );
};
export default Zoo;
