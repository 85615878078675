import css from "./style.module.css";
import vidgukOne from "../../img/vidgukOne.jpg";
import vidgukTwo from "../../img/vidgukTwo.jpg";
const Vidguk = () => {
  return (
    <div className={css.wrapVidguk}>
      <div className={css.wrapSmallVidguk}>
        <p className={css.pVidguk}>Відгуки клієнтів</p>
        <div className={css.wrapAllVidgukl}>
          <div className={css.wrapVidguks}>
            <img className={css.wrapPhotoVidguk} src={vidgukOne} alt="Photo" />
            <div className={css.wrapAllTextP}>
              <p className={css.wopPInVid}>
                Дуже вдячна клініці "LarinVet"! Ветеринари професійні та
                дбайливі. Мій кіт Томас швидко одужав завдяки їхньому лікуванню.
                Рекомендую!
              </p>
              <p className={css.wopPInVid}>Олена</p>
            </div>
          </div>{" "}
          <div className={css.wrapVidguks}>
            <img className={css.wrapPhotoVidguk} src={vidgukTwo} alt="Photo" />
            <div className={css.wrapAllTextP}>
              <p className={css.wopPInVid}>
                Чудова клініка! Мій собака Макс отримав тут найкращий догляд.
                Ветлікарі дуже уважні і компетентні. Вдячна за допомогу!
              </p>
              <p className={css.wopPInVid}>Іра</p>
            </div>
          </div>
          <div className={css.wrapVidguks}>
            <div className={css.krugVidguk}>N</div>
            <div className={css.wrapAllTextP}>
              <p className={css.wopPInVid}>
                Довіряю здоров'я своїх котиків тільки лікарю Андрію Дмитровичу,
                щиро дякую за професіоналізм та турботу.
              </p>
              <p className={css.wopPInVid}>Nadiia</p>
            </div>
          </div>
          <div className={css.wrapVidguks}>
            <div className={css.krugVidguk}>k</div>
            <div className={css.wrapAllTextP}>
              <p className={css.wopPInVid}>
                Залишилися з котиком дуже задоволені якістю обслуговування.
                Професійність та привітність працівників на вищому рівні.
                Звернулись сюди за рекомендацією подруги, яка вже давно є
                постійним клієнтом. Ми теж плануємо і надалі користуватись
                послугами цієї клініки
              </p>
              <p className={css.wopPInVid}>krgose</p>
            </div>
          </div>
          <div className={css.wrapVidguks}>
            <div className={css.krugVidguk}>Д</div>
            <div className={css.wrapAllTextP}>
              <p className={css.wopPInVid}>
                Рад был познакомиться со специалистами этой клиники. Отдельное
                большущее спасибо Андрею Дмитриевичу за спасение нашего пса.
              </p>
              <p className={css.wopPInVid}>Дмитрий</p>
            </div>
          </div>{" "}
          <div className={css.wrapVidguks}>
            <div className={css.krugVidguk}>А</div>
            <div className={css.wrapAllTextP}>
              <p className={css.wopPInVid}>
                Чудова ветеринарна клініка! Дуже привітні та компетентні лікарі.
                Кожен візит залишає максимально приємні враження!
              </p>
              <p className={css.wopPInVid}>Аліса</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Vidguk;
